/* eslint-disable */

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import $ from './dom';
import { extend, bindModuleMethods } from './swiper-utils';
var Circular = {
  setTranslate: function setTranslate(swiper) {
    // var swiper = this;
    var slides = swiper.slides,
        rtl = swiper.rtlTranslate;

    const { activeIndex, realIndex, previousIndex } = swiper

    const slideHeight = swiper.height
    const slideWidth = swiper.width
    const verticalTranslate = slideHeight * swiper.params.circularEffect.translateFactor

    for (var i = 0; i < slides.length; i += 1) {
      var $slideEl = slides.eq(i);
      var progress = $slideEl[0].progress;

      var rotateZ = swiper.params.circularEffect.itemRotation * i;
      var tx = 0;
      var ty = -verticalTranslate

      $slideEl[0].style.width = slideWidth + 'px'
      $slideEl[0].style.height = slideHeight + 'px'
      $slideEl[0].style.transformOrigin = '50% ' + -ty + 'px'
      $slideEl.transform("translateX(-50%) translateY(" + ty + "px) rotate(" + rotateZ + "deg)");
    }

    const currentIndex = (slides.length-1) * swiper.progress
    const rz = currentIndex * swiper.params.circularEffect.itemRotation;

    swiper.wrapperEl.style.top = verticalTranslate + 'px'
    swiper.wrapperEl.style.transform = 'rotate(' + -rz + 'deg)'
  },
  setTransition: function setTransition(duration) {
    var swiper = this;
    var slides = swiper.slides,
        activeIndex = swiper.activeIndex,
        realIndex = swiper.realIndex,
        previousIndex = swiper.previousIndex,
        $wrapperEl = swiper.$wrapperEl;
    slides.transition(duration)
  }
};
export default {
  name: 'effect-circular',
  params: {
    circularEffect: {
      slideShadows: false,
      radius: 200,
      itemRotation: 30,
      translateFactor: 1.5,
    }
  },
  create: function create() {
    var swiper = this;
    bindModuleMethods(swiper, {
      circularEffect: _extends({}, Circular)
    });
  },
  on: {
    beforeInit(swiper) {
      if (swiper.params.effect !== 'circular') return;
      swiper.classNames.push(swiper.params.containerModifierClass + "circular");
      var overwriteParams = {
        slidesPerView: 1,
        slidesPerColumn: 1,
        slidesPerGroup: 1,
        watchSlidesProgress: true,
        spaceBetween: 0,
        virtualTranslate: true
      };
      extend(swiper.params, overwriteParams);
      extend(swiper.originalParams, overwriteParams);
    },
    setTranslate(swiper) {
      if (swiper.params.effect !== 'circular') return;
      swiper.circularEffect.setTranslate(swiper);
    },
    setTransition(swiper, duration) {
      if (swiper.params.effect !== 'circular') return;
      swiper.circularEffect.setTransition(duration);
    }
  }
};
