<template>
  <footer
    v-intersect="{ onChange: onIntersection }"
    class="c-footer"
    :class="cssClass"
  >
    <div class="c-footer__path">
      <FooterWave />
    </div>
    <div
      class="c-footer__content"
      @mouseenter="onFooterHover"
    >
      <div class="c-footer__content-inner o-content-box--side u-width-max u-flex u-flex-justify-space-between">
        <BrandLogo
          class="c-footer__logo"
          :model="model.brandLogo"
        />
        <div class="c-footer__items u-flex u-flex-align-start">
          <FooterLinks
            v-if="model.column1Links.length"
            :links="model.column1Links"
          />
          <FooterLinks
            v-if="model.column2Links.length"
            :links="model.column2Links"
          />
        </div>
        <div>
          <SocialIcons :model="model.socialIcons" />
          <div
            v-if="model.copyrightText"
            class="c-menu__copyright"
          >
            {{ model.copyrightText }}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterWave from '@/assets/img/inline/footer.svg'
import BrandLogo from '@/components/navigation/BrandLogo.vue'
import SocialIcons from '@/components/navigation/SocialIcons.vue'
import FooterLinks from './FooterLinks.vue'

export default {
  components: {
    FooterWave,
    BrandLogo,
    SocialIcons,
    FooterLinks
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      wave: null,
      animation: null,
      startPath: 'm 0,63.666769 c 0,0 405.20152,-102.013911 1016.0335,-47.778437 C 1327.6448,43.556155 1800,10.658867 1800,10.658867 V 100 H 0 Z',
      midPath: 'm 0,45.063284 c 0,0 578.81731,-26.981411 1222.0404,-34.716563 C 1522.2983,6.7359357 1800,32.517611 1800,32.517611 V 100 H 0 Z',
      endPath: 'm 0,29.918314 c 0,0 169.09733,82.070736 1312.0435,-12.275086 C 1672.3093,-12.095338 1800,54.538995 1800,54.538995 V 100 H 0 Z',
      isIntersecting: false
    }
  },
  computed: {
    cssClass() {
      return [
        this.isIntersecting ? 'in-view' : ''
      ]
    }
  },
  mounted() {
    this.wave = document.querySelector('.c-footer__path path.wave')
    this.initAnimation()
  },
  methods: {
    onIntersection(e) {
      this.isIntersecting = e

      if (this.isIntersecting) {
        this.animation.restart()
      } else {
        this.animation.pause()
      }
    },
    onFooterHover() {
      if (this.animation.completed) {
        this.$anime({
          targets: this.wave,
          d: [
            this.startPath,
            this.midPath,
            this.endPath
          ],
          easing: 'linear',
          duration: 800,
          direction: 'alternate',
          loop: 2
        })
      }
    },
    initAnimation() {
      this.$anime.set(this.wave, { d: this.startPath })

      this.animation = this.$anime
        .timeline({
          targets: this.wave,
          autoplay: false,
          easing: 'linear',
          duration: 800,
          delay: 50,
          endDelay: 0,
          direction: 'alternate',
          loop: 4
        })
        .add({
          d: [
            this.startPath
          ]
        })
        .add({
          d: [
            this.midPath
          ]
        })
        .add({
          d: [
            this.endPath
          ]
        })
    }
  }
}
</script>
