<template>
  <div class="c-competition-hero js-parallax-wrapper">
    <div class="curved-lines">
      <div class="curved-multilines brown use-wave" />
      <div class="curved-multilines pink use-wave" />
      <div class="curved-multilines blue use-wave" />
      <div class="curved-multilines yellow use-wave" />
      <div class="curved-multilines block-bg use-wave" />
    </div>
    <div class="c-competition-hero-media use-wave">
      <Picture
        v-if="model.foregroundImage"
        ref="picture"
        :src="foregroundImage"
        :resizer="model.imageResizer.name"
        :mobile="[767,]"
        :tablet="[991,]"
        :desktop="[1200,]"
        :wide="[1920,]"
        :alt="model.contentImageAlt"
        class="c-competition-hero-image js-parallax-visual"
      />
    </div>
    <div class="c-competition-hero-inner js-parallax-text">
      <div class="c-competition-hero-content u-text-align--center">
        <Picture
          v-if="model.contentImage && model.contentImage.url"
          ref="picture"
          :src="model.contentImage"
          :resizer="model.imageResizer.name"
          :mobile="[767,]"
          :tablet="[991,]"
          :desktop="[1200,]"
          :wide="[1920,]"
          :alt="model.contentImageAlt"
        />
      </div>
    </div>
    <svg
      height="0"
      width="0"
    >
      <clipPath
        id="wave-clip-path"
        clipPathUnits="objectBoundingBox"
      >
        <path d="m0,1 c0,0,0.226,-0.118,0.566,-0.055 C0.739,0.977,1,0.911,1,0.911 V0 H0" />
      </clipPath>
    </svg>
    <svg
      height="0"
      width="0"
    >
      <clipPath
        id="wave-mobile-clip-path"
        clipPathUnits="objectBoundingBox"
      >
        <path d="M 0,1 C 0,1 0.31349293333333333,0.9529926231527094 0.5381168000000001,0.9718602586206897 0.72364384,0.9874438916256157 1.0133333333333334,0.9565422783251231 1.0133333333333334,0.9565422783251231 L 1.008,0 H 0 Z" />
      </clipPath>
    </svg>
  </div>
</template>

<script>
import Picture from '@/components/Picture.vue'
import initParallax from '@/effects/parallax'

export default {
  components: {
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    foregroundImage() {
      if (this.$mq === 'mobile' || this.$mq === 'tablet') {
        return this.model.mobileForegroundImage || this.model.foregroundImage
      }

      return this.model.foregroundImage
    }
  },
  mounted() {
    initParallax()
  }
}
</script>
