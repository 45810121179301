<template>
  <div
    class="c-fullwidth js-parallax-wrapper"
    :class="{
      'use-wave': model.addWave
    }"
  >
    <svg
      v-if="model.addWave"
      height="0"
      width="0"
    >
      <clipPath
        id="wave-clip-path"
        clipPathUnits="objectBoundingBox"
      >
        <path d="m0,1 c0,0,0.226,-0.118,0.566,-0.055 C0.739,0.977,1,0.911,1,0.911 V0 H0" />
      </clipPath>
    </svg>
    <svg
      v-if="model.addWave"
      height="0"
      width="0"
    >
      <clipPath
        id="wave-mobile-clip-path"
        clipPathUnits="objectBoundingBox"
      >
        <path d="M 0,1 C 0,1 0.31349293333333333,0.9529926231527094 0.5381168000000001,0.9718602586206897 0.72364384,0.9874438916256157 1.0133333333333334,0.9565422783251231 1.0133333333333334,0.9565422783251231 L 1.008,0 H 0 Z" />
      </clipPath>
    </svg>

    <div class="c-fullwidth-media">
      <Picture
        v-if="backgroundImage && backgroundImage.url && !backgroundVideo"
        ref="picture"
        :src="backgroundImage"
        :resizer="model.imageResizer.name"
        :mobile="[767,]"
        :tablet="[991,]"
        :desktop="[1200,]"
        :wide="[1920,]"
        :alt="model.backgroundImageAlt"
        class="c-fullwidth-image js-parallax-visual"
      />
      <Video
        v-if="backgroundVideo"
        ref="video"
        :src="backgroundVideo"
        :poster="backgroundImage && backgroundImage.url ? backgroundImage.url : null"
        class="c-fullwidth-video js-parallax-visual"
      />
    </div>
    <div
      class="c-fullwidth-overlay"
      :style="{ 'opacity': model.overlayOpacity / 100 }"
    />

    <div class="c-fullwidth-inner js-parallax-text">
      <div class="c-fullwidth-content u-text-align--center">
        <h5
          v-if="model.subtitle"
          class="c-fullwidth-inner-subheading e-pre-heading"
        >
          {{ model.subtitle }}
        </h5>
        <component
          :is="model.titleIsH1 ? 'h1' : 'h2'"
          v-if="model.title"
          class="c-fullwidth-inner-heading"
          v-html="model.title"
        />
        <Link
          v-if="model.link.url"
          :model="model.link"
          class="c-button"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Link from '@/components/Link.vue'
import Picture from '@/components/Picture.vue'
import Video from '@/components/Video.vue'
import initParallax from '@/effects/parallax'

export default {
  components: {
    Link,
    Picture,
    Video
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    backgroundImage() {
      if (this.$mq === 'mobile' || this.$mq === 'tablet') {
        return this.model.mobileBackgroundImage || this.model.backgroundImage
      }

      return this.model.backgroundImage
    },
    backgroundVideo() {
      if (this.$mq === 'mobile' || this.$mq === 'tablet') {
        return this.model.mobileBackgroundVideo || this.model.backgroundVideo
      }

      return this.model.backgroundVideo
    }
  },
  mounted() {
    initParallax()
  }
}
</script>
