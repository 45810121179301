<template>
  <div
    class="c-top-nav"
    :class="{
      'floating': model.isFloating,
      'nav-open': navOpen
    }"
  >
    <header class="c-header u-flex u-flex-justify-space-between">
      <BrandLogo
        class="c-header__logo"
        :model="model.brandLogo"
      />
      <div
        class="c-header__burger"
        @click="onMenuClick"
      >
        <BurgerMenu />
      </div>
    </header>
    <MainMenu
      :model="model.mainMenu"
    />
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import BurgerMenu from '@/assets/img/inline/burger_menu.svg'
import BrandLogo from '@/components/navigation/BrandLogo.vue'
import MainMenu from '@/components/navigation/MainMenu.vue'

export default {
  components: {
    BurgerMenu,
    BrandLogo,
    MainMenu
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mainMenu: null,
      navOpen: false
    }
  },
  mounted() {
    document.documentElement.style.setProperty('--ih', `${window.innerHeight}px`)
    this.mainMenu = document.getElementById('nav-content')
  },
  methods: {
    onMenuClick() {
      this.navOpen = !this.navOpen

      if (this.navOpen) {
        disableBodyScroll(this.mainMenu)
      } else {
        enableBodyScroll(this.mainMenu)
      }
    }
  }
}
</script>
