<template>
  <div class="u-width-text u-mb--l u-mt--l u-text-align--center">
    <h1
      v-if="model.title"
      v-html="model.title"
    />
    <Link
      v-if="model.link.url"
      :model="model.link"
      class="c-button"
    />
  </div>
</template>

<script>
import Link from '@/components/Link.vue'

export default {
  components: {
    Link
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
