<template>
  <div ref="playerContainer">
    <div ref="player" />
  </div>
</template>

<script>
import youtubeLoader from '@/utils/youtube-loader'
import store from '@/helpers/store'
import viewPortDetect from '@/utils/in-viewport-detect'

export default {
  props: {
    videoId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      store,
      videoStart: false,
      videoComplete: false,
      interval: null,
      passed10: false,
      passed25: false,
      passed50: false,
      passed75: false,
      passed90: false
    }
  },
  mounted() {
    viewPortDetect.observeInViewport(this.$refs.playerContainer, () => {
      youtubeLoader.loadYoutubePlayer()
    })
    const checkYT = setInterval(() => {
      if (store.state.isYoutubeLoaded) {
        clearInterval(checkYT)
        this.initPlayer()
      }
    }, 20)
  },
  methods: {
    initPlayer() {
      /* eslint-disable */
      this.player = new YT.Player(this.$refs.player, {
        /* eslint-enable */
        videoId: this.videoId,
        host: 'https://www.youtube-nocookie.com',
        playerVars: {
          controls: 1,
          showinfo: 0,
          rel: 0
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.checkState
        }
      })
    },
    onPlayerReady() {
      this.interval = setInterval(() => {
        if (this.player.getPlayerState() === 1) {
          this.onPlayProgress()
        }
      }, 1000)
    },
    checkState() {
      if (this.player.getPlayerState() === 1 && !this.videoStart) {
        this.videoStart = true
        window.dataLayer.push({
          event: 'videoStart',
          videoTitle: this.player.videoTitle
        })
      }
      if (this.player.getPlayerState() === 0 && !this.videoComplete) {
        this.videoComplete = true
        window.dataLayer.push({
          event: 'videoComplete',
          videoTitle: this.player.videoTitle
        })
        clearInterval(this.interval)
      }
    },
    onPlayProgress() {
      const viewedPercentage = this.player.getCurrentTime() / this.player.getDuration()
      if (viewedPercentage >= 0.9 && !this.passed90) {
        this.passed90 = true
        this.pushProgress('90%')
      } else if (viewedPercentage >= 0.75 && !this.passed75) {
        this.passed75 = true
        this.pushProgress('75%')
      } else if (viewedPercentage >= 0.5 && !this.passed50) {
        this.passed50 = true
        this.pushProgress('50%')
      } else if (viewedPercentage >= 0.25 && !this.passed25) {
        this.passed25 = true
        this.pushProgress('25%')
      } else if (viewedPercentage >= 0.1 && !this.passed10) {
        this.passed10 = true
        this.pushProgress('10%')
      }
    },
    pushProgress(percent) {
      window.dataLayer.push({
        event: 'videoProgress',
        videoTitle: this.player.videoTitle,
        videoPercent: percent
      })
    }
  }
}
</script>
