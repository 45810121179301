import '@/main.scss'
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import VueIntersect from 'vue-intersect-directive'
import VueAnime from 'vue-animejs'
import VueMq from 'vue-mq'
import AOS from 'aos'

import vueUtil from '@/utils/vue-util'
import initParallax from '@/effects/parallax'
import formBlock from '@/effects/forms'
import '@/utils/iframeDynamicHeight'

// blocks
import ProductSliderBlock from '@/components/blocks/ProductSliderBlock.vue'
import BillboardBlock from '@/components/blocks/BillboardBlock.vue'
// import BillboardBlockSwiper from '@/components/blocks/BillboardBlockSwiper.vue'
import FullWidthBlock from '@/components/blocks/FullWidthBlock.vue'
import CompetitionHeroBlock from '@/components/blocks/CompetitionHeroBlock.vue'

// navigation
import Footer from '@/components/navigation/Footer.vue'
import Header from '@/components/navigation/Header.vue'

// pages
import ProductPage from '@/components/pages/ProductPage.vue'
// shared
import MediaOverlay from '@/components/MediaOverlay.vue'

import 'aos/dist/aos.css'

// pages
import NotFoundPage from '@/components/pages/NotFoundPage.vue'

// form elements
import InvisibleRecaptchaElementBlock from '@/components/form-elements/InvisibleRecaptchaElementBlock.vue'
import PermissionElementBlock from '@/components/form-elements/PermissionElementBlock.vue'

Vue.use(VueCompositionApi)
Vue.use(VueIntersect)
Vue.use(VueAnime)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 769,
    desktop: 992,
    wide: 1200,
    extra_wide: Infinity
  }
})

const components = {
  // blocks
  ProductSliderBlock,
  BillboardBlock,
  // BillboardBlockSwiper,
  FullWidthBlock,
  CompetitionHeroBlock,

  // navigation
  Footer,
  Header,
  // pages
  ProductPage,

  // shared
  MediaOverlay,

  // pages
  NotFoundPage,

  // form elements
  InvisibleRecaptchaElementBlock,
  PermissionElementBlock
}

vueUtil.mountComponents(components)

AOS.init()
initParallax()
formBlock()
