<template>
  <component
    :is="tag"
    :href="model.url || null"
    :class="{
      [activeClass]: model.isActive,
      [model.cssClass]: model.cssClass
    }"
    v-bind="model.attributes"
  >
    {{ model.text }}
  </component>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    emptyUrlTag: {
      type: String,
      default: null
    },
    activeClass: {
      type: String,
      default: 'active'
    }
  },
  computed: {
    tag() {
      const emptyUrlTag = this.emptyUrlTag ?? this.model.emptyUrlTag
      return this.model.url || !emptyUrlTag ? 'a' : emptyUrlTag
    }
  }
}
</script>
