const deferJQuery = (method) => {
  if (window.jQuery) {
    method()
  } else {
    setTimeout(() => { deferJQuery(method) }, 50)
  }
}

const deferEpiforms = (method) => {
  if (window.$$epiforms && window.jQuery) {
    method()
  } else {
    setTimeout(() => { deferEpiforms(method) }, 50)
  }
}

export { deferJQuery, deferEpiforms }
